
import { ref, Ref, defineComponent, computed, onActivated, onDeactivated, watch } from 'vue'
import Container from '@/components/Container.vue'
import TableCard from '@/components/TableCard.vue'
import { getTxsArId } from '@/libs/api'
import Pagination from '@/components/common/Pagination.vue'
import { useRoute } from 'vue-router'
import Loading from '@/components/common/Loading.vue'
import NotFound from '@/components/common/NotFound.vue'
import { useI18n } from 'vue-i18n'
import { getExplorerUrl, handleErrorMsg } from '@/libs/utils'
import { ChainType } from '@/libs/everpay-js'
import { Txs } from './interface'
export default defineComponent({
  components: {
    TableCard,
    Container,
    Pagination,
    Loading,
    NotFound
  },
  setup () {
    const loading = ref(true)
    const currentPage = ref(1)
    const pageCacheIndex = ref(0)
    const pageCachelist = ref<number[]>([])
    const isNextPage = ref(false)
    const pageId = ref(0)
    const notFound = ref(false)
    const route = useRoute()
    const { t } = useI18n()
    const arId = computed(() => {
      return route.params.arId as string
    })
    const formatArId = computed(() => {
      if (arId.value) {
        return (arId.value).slice(0, 6) + '...' + (arId.value).slice(-6)
      }
      return ''
    })
    const arIdUrl = getExplorerUrl({
      type: 'tx',
      value: arId.value
    }, ChainType.arweave)
    const txsTbodyList: Ref<Txs[]> = ref([])
    watch(() => arId.value, () => {
      getTxsList()
    })
    const getTxsList = async () => {
      loading.value = true
      notFound.value = false
      try {
        if (pageCachelist.value[pageCacheIndex.value] !== undefined) {
          pageId.value = pageCachelist.value[pageCacheIndex.value]
        }
        const arIdResult = await getTxsArId(arId.value, pageId.value)
        isNextPage.value = arIdResult.hasNextPage
        if (pageCachelist.value[pageCacheIndex.value] === undefined) {
          pageCachelist.value.push(pageId.value)
        }
        if (arIdResult.hasNextPage) {
          pageId.value = arIdResult.txs[arIdResult.txs.length - 1].rawId
        }
        if (!arIdResult.txs.length) {
          notFound.value = true
        } else {
          txsTbodyList.value = arIdResult.txs
        }
      } catch (e:any) {
        notFound.value = true
        txsTbodyList.value = []
        handleErrorMsg(e, t)
      } finally {
        loading.value = false
      }
    }
    const handlePageChange = (page: number) => {
      currentPage.value = page
      pageCacheIndex.value = currentPage.value - 1
      getTxsList()
    }
    onActivated(() => {
      currentPage.value = 1
      pageCachelist.value = []
      isNextPage.value = false
      pageId.value = 0
      pageCacheIndex.value = 0
      getTxsList()
    })
    onDeactivated(() => {
      txsTbodyList.value = []
    })
    return {
      t,
      handlePageChange,
      txsTbodyList,
      notFound,
      arId,
      loading,
      arIdUrl,
      currentPage,
      formatArId,
      isNextPage
    }
  }
})
